import { Config } from './types';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import { useEffect, useState } from 'react';
import './Output.css';
import chevronright from '../icons/chevron-right.svg';
import info from '../icons/info.svg';

interface OutputProps {
  config: Config;
}

function Output(props: OutputProps) {
  const [isCollapse, setIsCollapse] = useState(true);
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    verifyNetworkConfigEmpty();
  });

  const verifyNetworkConfigEmpty = () => {
    if (props.config.network.mode === 'wifi') {
      if (props.config.network.auth_type === 'none') {
        if (!props.config.network.ssid) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      } else if (props.config.network.auth_type === 'basic') {
        if (!props.config.network.ssid || !props.config.network.password) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      } else if (props.config.network.auth_type === 'enterprise') {
        if (props.config.network.enterprise_auth.mode === 'tls') {
          if (
            !props.config.network.ssid ||
            !props.config.network.enterprise_auth.identity ||
            !props.config.network.enterprise_auth.cert ||
            !props.config.network.enterprise_auth.private_key
          ) {
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        } else if (props.config.network.enterprise_auth.mode === 'peap') {
          if (
            !props.config.network.ssid ||
            !props.config.network.enterprise_auth.identity ||
            !props.config.network.enterprise_auth.password
          ) {
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        }
      }
    } else {
      setDisabled(false);
    }

    const isErrorCertificate = props.config.certificates.length > 0 && props.config.certificates[props.config.certificates.length - 1].error
    if (isErrorCertificate) {
      setDisabled(true);
    }
  };

  return (
    <>
      <div className="form-group">
        {props.config.network.mode && (
          <label onClick={() => setIsCollapse(!isCollapse)} className={`label-clickable ${isCollapse ? '' : 'active'}`}>
            Preview Configuration
            <img src={chevronright} alt="chevronright" id="previewConfigurationI" className="chevronright" />
          </label>
        )}

        <div id="aceEditor" className={`input-container output has-animation advanced ${isCollapse ? 'is-hide' : ''}`}>
          <AceEditor
            mode="json"
            theme="tomorrow_night"
            fontSize={16}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={JSON.stringify(generateConfig(props.config), undefined, 4)}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
            }}
          />
        </div>
      </div>
      <hr />
      <div className="form-group">
        <p className="message">
          <img src={info} alt="info" id="info" className="info" /> Select the root folder of your USB flash drive in the
          "Save File" dialog.
        </p>
        <button id="generateConfigurationButton" type="submit" disabled={isDisabled} className="button primary">
          Generate Configuration
        </button>
      </div>
    </>
  );
}

export default Output;

export function generateConfig(rawConfig: Config): object {
  const getNetwork = () => {
    const ntp = rawConfig.ntp.filter((ntp) => ntp);
    const dns = rawConfig.network.dns.some((dns: string) => dns !== '')
      ? rawConfig.network.dns.filter((dns) => dns)
      : undefined;
    const fixed_ipv4 =
      rawConfig.network.fixed_ipv4.method === 'auto'
        ? {}
        : {
            fixed_ipv4: rawConfig.network.fixed_ipv4,
          };

    let config = {};
    if (rawConfig.network.mode === 'wifi') {
      config = {
        ...config,
        ssid: rawConfig.network.ssid,
        hidden: rawConfig.network.hidden,
        dns,
        ...fixed_ipv4,
      };
    }

    if (rawConfig.network.auth_type === 'none') {
      return {
        ...config,
        mode: rawConfig.network.mode,
        ntp,
        dns,
        ...fixed_ipv4,
      };
    } else if (rawConfig.network.auth_type === 'basic') {
      return {
        ...config,
        mode: rawConfig.network.mode,
        password: rawConfig.network.password,
        ntp,
        dns,
        ...fixed_ipv4,
      };
    } else if (rawConfig.network.auth_type === 'enterprise') {
      return {
        ...config,
        mode: rawConfig.network.mode,
        dns,
        ...fixed_ipv4,
        enterprise:
          rawConfig.network.enterprise_auth.mode === 'tls'
            ? {
                mode: rawConfig.network.enterprise_auth.mode,
                identity: rawConfig.network.enterprise_auth.identity,
                private_key_password:
                  rawConfig.network.enterprise_auth.private_key_password !== ''
                    ? rawConfig.network.enterprise_auth.private_key_password
                    : undefined,
                ca: rawConfig.network.enterprise_auth.ca !== '' ? rawConfig.network.enterprise_auth.ca : undefined,
                cert: rawConfig.network.enterprise_auth.cert,
                private_key: rawConfig.network.enterprise_auth.private_key,
              }
            : {
                mode: rawConfig.network.enterprise_auth.mode,
                identity: rawConfig.network.enterprise_auth.identity,
                password: rawConfig.network.enterprise_auth.password,
                ca: rawConfig.network.enterprise_auth.ca !== '' ? rawConfig.network.enterprise_auth.ca : undefined,
              },
        ntp,
      };
    }
  };

  const getProxy = () => {
    if (!rawConfig.proxy.ip_address && !rawConfig.proxy.port) return;

    const isThereOnlyIpAddress = rawConfig.proxy.ip_address && !rawConfig.proxy.port;
    if (isThereOnlyIpAddress) {
      return {
        proxy_server: {
          ip_address: rawConfig.proxy.ip_address,
        },
      };
     } else {
      return {
        proxy_server: {
          ip_address: rawConfig.proxy.ip_address,
          port: rawConfig.proxy.port,
        },
      };
     }
  };

  const getCertificates = () => {
    const certificates = rawConfig?.certificates
    if (!certificates?.length) { return []; }
    
    if (certificates[certificates.length - 1].base64 === '' ) {
        return certificates.slice(0, certificates.length - 1).map((cert) => cert.base64);
    }
    return certificates.map((cert) => cert.base64);
  };

  return {
    version: rawConfig.version,
    network: getNetwork(),
    timezone: rawConfig.timezone,
    proxy: getProxy(),
    timestamp: rawConfig.timestamp,
    certificates: getCertificates(),
    enrollment_token: rawConfig.enrollment_token !== '' ? rawConfig.enrollment_token : undefined,
  };
}
