import { pki, pki as NodeForgePki } from 'node-forge';

export type FileType = 'CA_CERTIFICATE' | 'USER_CERTIFICATE' | 'PRIVATE_KEY';

export function download(filename: string, text: string) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function encodeBase64(value: string) {
  return btoa(unescape(encodeURIComponent(value)));
}

export function decodeBase64(value: string) {
  return atob(btoa(unescape(encodeURIComponent(value))));
}

export function readFileAsString(fileInput: Blob, fileType: FileType) {
  if (fileInput === undefined) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      if (event?.target?.result === undefined) {
        return;
      }

      if (fileType === 'CA_CERTIFICATE' || fileType === 'USER_CERTIFICATE') {
        verifyCertificateFormat(event.target.result as string)? resolve(encodeBase64(event.target.result as string)): reject('Invalid certificate');
      } else if (fileType === 'PRIVATE_KEY') {
        verifyRSAPrivateKey(event.target.result as string)? resolve(encodeBase64(event.target.result as string)): reject('Invalid private key');
      } else {
        reject('Invalid file type');
      }
    };
    reader.onerror = reject;
    reader.readAsText(fileInput);
  });
}


function verifyCertificateFormat(certContent: string): boolean {
  try {
    // Parse the certificate
    const certificate: NodeForgePki.Certificate = pki.certificateFromPem(certContent);
    // If parsing is successful, the certificate format is valid
    return true; // The certificate is valid
  } catch (error) {
    console.error('Error verifying certificate:', error);
    return false; // The certificate is invalid
  }
}
function verifyRSAPrivateKey(privateKeyContent: string): boolean {
  const isExpectedPrivateKeyFormat = privateKeyContent.includes('-----BEGIN RSA PRIVATE KEY-----') && privateKeyContent.includes('-----END RSA PRIVATE KEY-----');
  return isExpectedPrivateKeyFormat;
}

export function isIpAddress(ipaddress: string) {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  ) {
    return true;
  }
  return false;
}
