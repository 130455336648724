import wordmark from '../icons/wordmark.svg';
import './OnBoarding.css';
import { useHistory } from 'react-router-dom';

function HomePage() {
  const history = useHistory();
  const redirectToStart = () => history.push('/start');

  return (
    <div className="onboarding-container">
      <img src={wordmark} alt="wordmark" className="wordmark" />
      <button id="redirectToStartButton" className="button primary complete-onboarding" onClick={redirectToStart}>
        Launch Configuration Generator
      </button>
    </div>
  );
}

export default HomePage;
