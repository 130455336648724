import wordmark from '../icons/wordmark.svg';
import './ErrorReport.css';

function ThanksSupport() {
  return (
    <div className="container error-report">
      <div>
        <img src={wordmark} alt="wordmark" className="wordmark" />
        <h1>Thank you for your submission</h1>
        <p>A ScreenCloud team member will be in touch shortly.</p>

        <a href="https://www.screencloud.com" className="button primary complete-onboarding">
          Back to Homepage
        </a>
      </div>
    </div>
  );
}

export default ThanksSupport;
