import wordmark from '../icons/wordmark.svg';
import learnmore from '../icons/external.svg';
import './OnBoarding.css';

function OnBoarding(props: { oncomplete: () => void }) {
  return (
    <div className="onboarding-container">
      <div className="wrapper">
        <img src={wordmark} alt="wordmark" className="wordmark" />

        <div className="text onboarding-note">
          <h2 className="onboarding-title">Configuration Generator</h2>
          <p className="onboarding-desc">
            This tool allows you to create a ScreenCloud OS provisioning USB flash drive.
            <br /> Please make sure you have a FAT formatted USB flash drive available for use.&nbsp;
            <a
              target="blank"
              href="https://support.screencloud.com/hc/en-gb/articles/360018688217-How-to-Create-a-FAT-Formatted-USB-Flash-Drive-for-your-ScreenCloud-OS-Provisioning-USB-Flash-Drive">
              Learn more&nbsp;
              <img src={learnmore} alt="Learn More" className="learnmore" />
            </a>
          </p>
        </div>
        <button
          id="onCompleteButton"
          className="button primary complete-onboarding"
          onClick={props.oncomplete}
          type="button">
          Got it, Let's start!
        </button>
      </div>
    </div>
  );
}

export default OnBoarding;
