import { useEffect, useState } from 'react';
import _ from 'lodash';
import { readFileAsString } from '../libs/utils';
import { NetworkConfig, Config, SetErrorReactHookForm, SetClearErrorsReactHookForm } from './types';
import { DeepMap, FieldValues, FieldError } from 'react-hook-form';
import wifi from '../icons/wifi.svg';
import ethernet from '../icons/ethernet.svg';
import eye from '../icons/eye.svg'
import eyeSlash from '../icons/eye-slash.svg'
import './Cert.css';
import remove from '../icons/close.svg';

export const defaultConfig: NetworkConfig = {
  auth_type: 'basic',
  ssid: '',
  hidden: false,
  password: '',
  enterprise_auth: {
    mode: 'tls',
    identity: '',
    private_key_password: '',
    ca: '',
    cert: '',
    private_key: '',
    password: '',
  },
  dns: [''],
  fixed_ipv4: {
    method: 'auto',
    default_gateway: '',
    address: '',
  },
};

interface NetworkProps {
  handleConfigChange: (key: keyof Config, value: any) => void;
  config: NetworkConfig;
  register: any;
  errors: DeepMap<FieldValues, FieldError>;
  setValue: any;
  unregister: any;
  setError: SetErrorReactHookForm ;
  clearError: SetClearErrorsReactHookForm;
}

export default function Network(props: NetworkProps) {
  const { config: network } = props;
  const handleConfigChange = (path: string, value: string | boolean) => {
    const newConfig = {
      ...network,
    };

    if (path === 'mode' && (!network.mode || network.mode === 'wifi') && network.auth_type === 'basic') {
      newConfig.auth_type = 'none';
    }

    // TODO This will be disabled for scos v1.4.11 because we do not have time to test enterprise with ethernet connection yet so it always be none
    if (path === 'mode' && value === 'ethernet') {
      newConfig.auth_type = 'none';
    }

    _.set(newConfig, path, value);
    props.handleConfigChange('network', newConfig);
  };

  return (
    <>
      <NetworkType {...props} config={network} handleConfigChange={handleConfigChange} />
      {network.mode && <Authentication {...props} config={network} handleConfigChange={handleConfigChange} />}
    </>
  );
}

interface Props {
  config: NetworkConfig;
  handleConfigChange: (path: string, value: string | boolean) => void;
  register: any;
  errors: DeepMap<FieldValues, FieldError>;
  setValue: any;
  unregister: any;
  scosVersion?: string;
  setError: SetErrorReactHookForm ;
  clearError: SetClearErrorsReactHookForm;
}

function NetworkType(props: Props) {
  const { config, handleConfigChange } = props;
  const [selectedNetworkType, setSelectedNetworkType] = useState<'wifi' | 'ethernet'>('wifi');

  useEffect(() => {
    if (config.mode === undefined) {
      handleConfigChange('mode', 'wifi');
    }
  });

  const setNetworkType = (path: string, value: 'wifi' | 'ethernet') => {
    setSelectedNetworkType(value);
    handleConfigChange(path, value);
  };

  return (
    <>
      <h3>Network</h3>
      <div className="form-group">
        <label>Choose Your Network Type</label>
        <div className="input-container">
          <div className="button-group">
            <button
              id="optionWifiCheckbox"
              type="button"
              className={`button  network-option ${selectedNetworkType === 'wifi' ? 'active' : ''}`}
              onClick={() => setNetworkType('mode', 'wifi')}>
              <img src={wifi} alt="wifi" className="wifi" />
              WiFi
            </button>
            <button
              id="optionEthernetCheckbox"
              type="button"
              className={`button network-option ${selectedNetworkType === 'ethernet' ? 'active' : ''}`}
              onClick={() => setNetworkType('mode', 'ethernet')}>
              <img src={ethernet} alt="ethernet" className="ethernet" />
              Ethernet
            </button>
          </div>
        </div>
      </div>
      {(config.mode === 'wifi' || selectedNetworkType === 'wifi') && <Identity {...props} />}
    </>
  );
}

function Identity({ config, handleConfigChange }: Props) {
  return (
    <div className="form-group">
      <label id="ssidTitle">Network Name (SSID)</label>
      <div className="input-container">
        <div className="field">
          <div className="control">
            <input
              id="ssidInput"
              className="input"
              type="text"
              value={config.ssid}
              onChange={(e) => {
                handleConfigChange('ssid', e.target.value);
              }}
              autoFocus
            />
            <small>
              Only 2.4GHz WiFi supported on Station P1,
              <br />
              2.4/5GHz WiFi supported on Station P1 Pro.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

function BasicAuth({ config, handleConfigChange }: Props) {
  const [isShowPassword, setShowPassword] = useState(false);
  return (
    <>
      <div className="form-group">
        <label id="passwordTitle">Password</label>
        <div className='icon-input'>
          <div className="input-container">
            <div className="field">
              <div className="control">
                <input
                  id="passwordInput"
                  className="input"
                  type={`${isShowPassword ? 'text' : 'password'}`}
                  value={config.password}
                  onChange={(e) => {
                    handleConfigChange('password', e.target.value);
                  }}
                />
                <img src={isShowPassword? eye : eyeSlash} alt="hidePassword" onClick={() => setShowPassword(!isShowPassword)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function AuthOptions({ config, handleConfigChange }: Props) {
  return (
    <div className="form-group">
      <label>Authentication</label>
      <div className="input-container">
        <div className="field">
          <div className="control">
            <div className="select is-fullwidth">
              <select
                id="authSelect"
                onChange={(e) => handleConfigChange('auth_type', e.target.value)}
                value={config.auth_type}>
                {config.mode === 'wifi' && <option value="basic">Basic (WPA2 Personal)</option>}
                <option value="enterprise">Enterprise (WPA2 Enterprise)</option>
                <option value="none">None (Open)</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function EnterpriseAuth(props: Props) {
  const { config, handleConfigChange, register, errors, setValue, unregister, setError, clearError } = props;

  useEffect(() => {
    setValue('caFile', config.enterprise_auth.ca);
    return () => unregister('caFile');
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="form-group">
        <label id="securityTitle">Security</label>
        <div className="input-container">
          <div className="field">
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  id="securitySelect"
                  onChange={(e) => {
                    handleConfigChange('enterprise_auth.mode', e.target.value);
                  }}>
                  <option value="tls">TLS</option>
                  <option value="peap">PEAP</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label id="caTitle">CA Certificate(s)</label>
        {
          errors['caFile']?.message ?
          <>
            <div className="certificate-upload error">
              <p id="caFileInputError" className="help is-danger error">
                {errors['caFile']?.message}
              </p>
              <div className="remove-ntp-btn" onClick={() => {
                clearError('caFile');
                handleConfigChange('enterprise_auth.ca', '');
              }}>
                <img src={remove} alt="remove" className="remove" />
              </div>
            </div>
          </>
          :
          <>
            <div className="certificate-upload">
              <input 
                id="caFile"
                type="file" 
                className="upload-background"
                onChange={async (e) => {
                  clearError('caFile');
                  if (!e.target.files) return;
                  const content = await readFileAsString(e.target.files[0], 'CA_CERTIFICATE').catch((err) => {
                    handleConfigChange('enterprise_auth.ca', '');
                    setError('caFile', { type: 'custom' ,message: err+('(s)') })}) as string;
                  if (content) {
                    handleConfigChange('enterprise_auth.ca', content);
                  }
                }}
              />
            </div>
          </>
        }
        <small>Only .pem format supported.</small>
      </div>
      <div className="form-group">
        <label id="identityTitle">User Identity</label>
        <div className="input-container">
          <div className="field">
            <div className="control">
              <input
                id="identityInput"
                className="input"
                type="text"
                onChange={(e) => {
                  handleConfigChange('enterprise_auth.identity', e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {config.enterprise_auth.mode === 'tls' ? <Tls {...props} /> : <Peap {...props} />}
    </>
  );
}

function Tls({ config, handleConfigChange, register, errors, setValue, unregister, setError, clearError } : Props) {

  useEffect(() => {
    setValue('tlsCertFile', config.enterprise_auth.cert);
    return () => unregister('tlsCertFile');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue('tlsPriKeyFile', config.enterprise_auth.private_key);
    return () => unregister('tlsPriKeyFile');
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <>
        <div className="form-group">
          <label id="tlsPriKeyTitle">User Private Key</label>
          {
          errors['tlsPriKeyFile']?.message ?
          <>
            <div className="certificate-upload error">
              <p id="tlsPriKeyInputError" className="help is-danger error">
                {errors['tlsPriKeyFile']?.message}
              </p>
              <div className="remove-ntp-btn" onClick={() => {
                clearError('tlsPriKeyFile');
                handleConfigChange('enterprise_auth.private_key', '');
              }}>
                <img src={remove} alt="remove" className="remove" />
              </div>
            </div>
          </>
          :
          <>
            <div className="certificate-upload">
              <input 
                id="tlsPriKeyFile"
                type="file" 
                className="upload-background"
                onChange={async (e) => {
                  clearError('tlsPriKeyFile');
                  if (!e.target.files) return;
                  const content = await readFileAsString(e.target.files[0], 'PRIVATE_KEY').catch((err) => {
                    handleConfigChange('enterprise_auth.private_key', '');
                    setError('tlsPriKeyFile', { type: 'custom' ,message: err })}) as string;
                  if (content) {
                    handleConfigChange('enterprise_auth.private_key', content);
                  }
                }}
              />
            </div>
          </>
          }
        </div>
        <div className="form-group">
          <label id="tlsPriKeyPasswordTitle">User Private Key Password</label>
          <div className="input-container">
            <div className="field">
              <div className="control">
                <input
                  key="tls-password"
                  id="tlsPriKeyPasswordInput"
                  className="input "
                  type="text"
                  onChange={(e) => handleConfigChange('enterprise_auth.private_key_password', e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label id="tlsCertTitle">User Certificate</label>
          {
          errors['tlsCertFile']?.message ?
          <>
            <div className="certificate-upload error">
              <p id="tlsCertInputError" className="help is-danger error">
                {errors['tlsCertFile']?.message}
              </p>
              <div className="remove-ntp-btn" onClick={() => {
                clearError('tlsCertFile');
                handleConfigChange('enterprise_auth.private_key', '');
              }}>
                <img src={remove} alt="remove" className="remove" />
              </div>
            </div>
          </>
          :
          <>
            <div className="certificate-upload">
              <input 
                id="tlsCertFile"
                type="file" 
                className="upload-background"
                onChange={async (e) => {
                  clearError('tlsCertFile');
                  if (!e.target.files) return;
                  const content = await readFileAsString(e.target.files[0], 'USER_CERTIFICATE').catch((err) => {
                    handleConfigChange('enterprise_auth.cert', '');
                    setError('tlsCertFile', { type: 'custom' ,message: err })}) as string;
                  if (content) {
                    handleConfigChange('enterprise_auth.cert', content);
                  }
                }}
              />
            </div>
          </>
          }
          <small>Only .pem format supported.</small>
        </div>
      </>
    </div>
  );
}

function Peap({ config, handleConfigChange, register, errors, setValue, unregister, setError, clearError }: Props) {
  const [isShowPassword, setShowPassword] = useState(false);

  return (
    <div>
      <>
        <div className="form-group">
          <label id="peapPasswordTitle">User Password</label>
          <div className='icon-input'>
            <div className="input-container">
              <div className="field">
                <div className="control">
                  <input
                    key="peap-password"
                    id="peapPasswordInput"
                    className="input"
                    type={`${isShowPassword ? 'text' : 'password'}`}
                    onChange={(e) => {
                      handleConfigChange('enterprise_auth.password', e.target.value);
                    }}
                  />
                  <img src={isShowPassword? eye : eyeSlash} alt="hidePassword" onClick={() => setShowPassword(!isShowPassword)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

function Authentication(props: Props) {
  const { config } = props;

  if (config.mode === 'ethernet') return <></>;

  return (
    <>
      <AuthOptions {...props} />
      {config.auth_type === 'basic' && <BasicAuth {...props} />}
      {config.auth_type === 'enterprise' && <EnterpriseAuth {...props} />}
    </>
  );
}
