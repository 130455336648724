import React from 'react';
import './ToggleSwitch.css';

interface Props {
  id: string;
  name: string;
  checked: boolean;
  onChange: () => void;
  optionLabels: string[];
  small: boolean;
  disabled: boolean;
}

const ToggleSwitch = ({ id, name, checked, onChange, optionLabels, small, disabled }: Props) => {
  return (
    <div className={'toggle-switch' + (small ? ' small-switch' : '')}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={() => onChange()}
        disabled={disabled}
      />
      {id ? (
        <label className="toggle-switch-label" htmlFor={id}>
          <span
            className={disabled ? 'toggle-switch-inner toggle-switch-disabled' : 'toggle-switch-inner'}
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
          />
          <span
            id="hiddenTogle"
            className={disabled ? 'toggle-switch-switch toggle-switch-disabled' : 'toggle-switch-switch'}
          />
        </label>
      ) : null}
    </div>
  );
};

export default ToggleSwitch;
