import { IPv4Config } from './types';
import { DeepMap, FieldValues, FieldError } from 'react-hook-form';
import { useEffect } from 'react';

interface IPv4Props {
  handleConfigChange: (path: string, value: any) => void;
  config: IPv4Config;
  register: any;
  errors: DeepMap<FieldValues, FieldError>;
  setValue: any;
  unregister: any;
}

function IPv4({ config, handleConfigChange, register, errors, setValue, unregister }: IPv4Props) {
  useEffect(() => {
    setValue('fixedIpv4address', config.address);
    return () => unregister('fixedIpv4address');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (config.method === 'auto') {
      unregister('fixedIpv4address');
    } else {
      setValue('fixedIpv4address', config.address);
    }
  }, [config.method, unregister, setValue, config.address]);

  return (
    <>
      <div className="form-group">
        <label id="ipv4MethodTitle">Option</label>
        <div className="input-container">
          <div className="field">
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  id="ipv4MethodSelect"
                  onChange={(e) => {
                    handleConfigChange('fixed_ipv4.method', e.target.value);
                  }}
                  value={config.method}>
                  <option value="auto">Automatic (DHCP)</option>
                  <option value="manual">Manual (Static IP)</option>
                </select>
              </div>
              {config.method === 'manual' && <small>Only IPv4 supported.</small>}
            </div>
          </div>
        </div>
      </div>
      {config.method === 'manual' && (
        <div>
          <div className="form-group has-validation">
            <label id="addressTitle" className="has-validation">
            IP Address (CIDR Format)
            </label>
            <div className="input-container has-validation">
              <div className="field">
                <div className="control has-validation">
                  <input
                    id="addressInput"
                    className={`input ${errors['fixedIpv4address'] ? 'is-danger' : ''}`}
                    type="text"
                    placeholder="192.168.1.2/24"
                    onChange={(e) => {
                      setValue('fixedIpv4address', e.target.value);
                      handleConfigChange('fixed_ipv4.address', e.target.value);
                    }}
                    value={config.address}
                    {...register('fixedIpv4address', {
                      required: 'This field is required',
                      pattern: {
                        value:
                          /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}\/(3[0-2]|[12]?[0-9])$/i,
                        message: 'Must be valid IPv4 CIDR',
                      },
                    })}
                  />
                  <p id="addressInputError" className="help is-danger">
                    {errors['fixedIpv4address']?.message}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label id="gatewayTitle">Gateway</label>
            <div className="input-container">
              <div className="field">
                <div className="control">
                  <input
                    id="gatewayInput"
                    className="input "
                    type="text"
                    placeholder="192.168.1.1"
                    onChange={(e) => handleConfigChange('fixed_ipv4.default_gateway', e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default IPv4;
