import { useCallback, useEffect, useState } from 'react';
import { Config, Cert as CertType } from './types';
import { readFileAsString } from '../libs/utils';
import './Cert.css';
import remove from '../icons/close.svg';
import addmore from '../icons/plus-circle.svg';

export const defaultConfig: string[] = [];

interface CertProps {
  handleConfigChange: (key: keyof Config, value: any) => void;
  config: CertType[];
  certificateLists: CertType[];
  onCertificateChange: (certificates: CertType[]) => void;
}

function Certificate(props: CertProps) {
  const [certificates, setCertificate] = useState<CertType[]>(props.certificateLists);

  useEffect(() => {
    props.onCertificateChange(certificates)
    props.handleConfigChange('certificates', certificates);
  }, [certificates]);

  const handleAddCert = useCallback(() => {
    setCertificate((prev) => [...prev, {base64: '', filename: ''}])
  }, []);

  const handleRemoveCert = useCallback(
    (index) => {
      if (props.certificateLists.length === 1) {
        setCertificate([{base64: '', filename: ''}]);
        return;
      }
      const newCert = props.certificateLists.filter((_, i) => {
        return index !== i;
      });
      setCertificate(newCert);
    },[props.certificateLists]
  );

  const handleUserCertUpload = async (e: React.ChangeEvent<HTMLInputElement>, index:number) => {
    try {
      if (!e.target.files || e.target.files.length === 0) return
      const file = e.target.files[0]
      const base64 = await readFileAsString(file, 'USER_CERTIFICATE') as string
      
      if (!base64) return

      setCertificate((prev) => {
        const newCert = [...prev]
        newCert[index] = { base64, filename: file.name }
        return newCert
      })
      props.onCertificateChange(certificates)
    } catch (error : any) {
      setCertificate((prev) => {
        const newCert = [...prev]
        newCert[index] = { base64: '', filename: '' , error: error }
        return newCert
      })
    }
  }

  return (
    <div className="form-group">
      <label id="certTitle" className="certificte-title">Deploy trusted sites certificate(s)</label>
      <div className="certificates-container">
      {certificates.map((cert, index) => (
            certificates[index].filename !== '' && certificates[index].base64 !== '' ? 
              <>
                <div className="control" key={index}>
                  <input id="certFileNameInput" className="input" type="text" value={cert.filename} disabled />
                  <div className="remove-ntp-btn" onClick={() => handleRemoveCert(index)}>
                    <img src={remove} alt="remove" className="remove" />
                  </div>
                </div>
              </>
              :
              certificates[index].error ? 
              <>
                <div className="error">
                  <p id="errorCertFileNameInput" className="input help is-danger">Invalid certificate</p>
                  <div className="remove-ntp-btn" onClick={() => {
                    handleRemoveCert(index)
                  }}>
                    <img src={remove} alt="remove" className="remove" />
                  </div>
                </div>
              </> 
              : 
              <>
                <div className="upload">
                  <input 
                    id="uploadCertFileNameInput"
                    type="file" 
                    className="upload-background"
                    onChange={(e) => handleUserCertUpload(e, index)}
                    />
                  <div className="remove-ntp-btn" onClick={() => handleRemoveCert(index)}>
                    <img src={remove} alt="remove" className="remove" />
                  </div>
                </div>
              </>
        ))
      }
      </div>
      <button id="handleAddCertButton" disabled={certificates[certificates.length-1].filename !== '' ? false : true} className="button add-more-btn" onClick={handleAddCert} type="button">
        <img src={addmore} alt="addmore" className="addmore" />
        Add more
      </button>
      <small>Only .pem format supported.</small>
    </div>
  );
}

export default Certificate;
