import _ from 'lodash';
import { ProxyConfig, Config } from './types';
import './Proxy.css';

export const defaultConfig: ProxyConfig = {
  ip_address: '',
  port: undefined,
};

interface ProxyProps {
  handleConfigChange: (key: keyof Config, value: any) => void;
  config: ProxyConfig;
}

function Proxy(props: ProxyProps) {
  const { config } = props;

  const handleConfigChange = (key: keyof ProxyConfig, value: string | boolean | number) => {
    const newConfig = {
      ...config,
    };
    _.set(newConfig, key, value);
    props.handleConfigChange('proxy', newConfig);
  };

  return (
    <>
      <div className="proxy-container">
        <div className="form-group ip-address">
          <label id="hostTitle">Host</label>
          <div className="input-container">
            <div className="field">
              <div className="control">
                <input
                  id="ipAddressInput"
                  className="input "
                  type="text"
                  onChange={(e) => handleConfigChange('ip_address', e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group port">
          <label id="portTitle">Port</label>
          <div className="input-container">
            <div className="field">
              <div className="control">
                <input
                  id="portInput"
                  className="input "
                  type="number"
                  onChange={(e) => handleConfigChange('port', parseInt(e.target.value))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Proxy;
