import { useState } from 'react';
import { ProxyConfig, Config, Cert as CertType, NetworkConfig } from './types';
import Ntp from './Ntp';
import Proxy from './Proxy';
import Cert from './Cert';
import './Advanced.css';
import { DeepMap, FieldValues, FieldError } from 'react-hook-form';
import IPv4 from './IPv4';
import _ from 'lodash';
import ToggleSwitch from './ToggleSwitch';
import chevronright from '../icons/chevron-right.svg';
import { useCallback } from 'react';
import addmore from '../icons/plus-circle.svg';
import remove from '../icons/close.svg';
export const defaultConfig: string[] = [];

interface AdvanceProps {
  handleConfigChange: (key: keyof Config, value: any) => void;
  ntp: string[];
  proxy: ProxyConfig;
  certificates: CertType[];
  config: NetworkConfig;
  register: any;
  errors: DeepMap<FieldValues, FieldError>;
  setValue: any;
  unregister: any;
}

function Advanced(props: AdvanceProps) {
  const { config: network } = props;
  const handleConfigChange = (path: string, value: any) => {
    const newConfig = {
      ...network,
    };

    if (path === 'mode' && (!network.mode || network.mode === 'wifi') && network.auth_type === 'basic') {
      newConfig.auth_type = 'none';
    }

    // TODO This will be disabled for scos v1.4.11 because we do not have time to test enterprise with ethernet connection yet so it always be none
    if (path === 'mode' && value === 'ethernet') {
      newConfig.auth_type = 'none';
    }

    _.set(newConfig, path, value);
    props.handleConfigChange('network', newConfig);
  };
  const [isCollapse, setIsCollapse] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState<'tcp/ip' | 'dns' | 'proxy' | 'ntp' | 'cert'>('tcp/ip');
  const [certificates, setCertificate] = useState<CertType[]>([{base64: '', filename: ''}]);

  const onCertificateChange = (certType: CertType[]) => {
    setCertificate(certType);
  };

  return (
    <>
      <div className="form-group">
        <label onClick={() => setIsCollapse(!isCollapse)} className={`label-clickable ${isCollapse ? '' : 'active'}`}>
          Advanced Network
          <img src={chevronright} alt="chevronright" id="advancedI" className="chevronright" />
        </label>
        <div className={`input-container has-animation advanced ${isCollapse ? 'is-hide' : ''}`}>
          <HiddenOption {...props} config={network} handleConfigChange={handleConfigChange} />
          <div className="button-group has-addons advanced-menu">
            <button
              id="tcpipButton"
              type="button"
              className={`button ${selectedMenu === 'tcp/ip' ? 'active' : ''}`}
              onClick={() => setSelectedMenu('tcp/ip')}>
              IP Address
            </button>
            <button
              id="dnsButton"
              type="button"
              className={`button ${selectedMenu === 'dns' ? 'active' : ''}`}
              onClick={() => setSelectedMenu('dns')}>
              DNS
            </button>
            <button
              id="proxyButton"
              type="button"
              className={`button ${selectedMenu === 'proxy' ? 'active' : ''}`}
              onClick={() => setSelectedMenu('proxy')}>
              Proxy
            </button>
            <button
              id="ntpButton"
              type="button"
              className={`button ${selectedMenu === 'ntp' ? 'active' : ''}`}
              onClick={() => setSelectedMenu('ntp')}>
              NTP
            </button>
            <button
              id="certButton"
              type="button"
              className={`button ${selectedMenu === 'cert' ? 'active' : ''}`}
              onClick={() => setSelectedMenu('cert')}>
              Trusted Sites Certificate(s)
            </button>
          </div>
          {selectedMenu === 'ntp' && <Ntp config={props.ntp} handleConfigChange={props.handleConfigChange} />}
          {selectedMenu === 'proxy' && <Proxy config={props.proxy} handleConfigChange={props.handleConfigChange} />}
          {selectedMenu === 'cert' && (
            <Cert config={props.certificates} certificateLists={certificates} handleConfigChange={props.handleConfigChange} onCertificateChange={onCertificateChange} />
          )}
          {selectedMenu === 'tcp/ip' && (
            <IPv4 {...props} handleConfigChange={handleConfigChange} config={props.config.fixed_ipv4} />
          )}
          {selectedMenu === 'dns' && <DNS {...props} handleConfigChange={handleConfigChange} config={network} />}
        </div>
      </div>
    </>
  );
}

interface Props {
  config: NetworkConfig;
  handleConfigChange: (path: string, value: string | string[] | boolean) => void;
  register: any;
  errors: DeepMap<FieldValues, FieldError>;
  setValue: any;
  unregister: any;
  scosVersion?: string;
}

function DNS({ config, handleConfigChange }: Props) {
  const handleDNSChange = useCallback(
    (e, index) => {
      config.dns[index] = e.target.value;
      handleConfigChange('dns', [...config.dns]);
    },
    [config.dns, handleConfigChange]
  );

  const onRemoveDNS = useCallback(
    (index) => {
      const newDNS = config.dns.filter((_, i) => {
        return index !== i;
      });

      handleConfigChange('dns', newDNS);
    },
    [handleConfigChange, config.dns]
  );

  const onAddDNS = useCallback(() => {
    handleConfigChange('dns', [...config.dns, '']);
  }, [config.dns, handleConfigChange]);

  return (
    <div className="form-group">
      <label id="dnsTitle">Server IP Address</label>
      <div className="input-container multiple">
        {config.dns.map((value, index) => (
          <div className="control" key={index}>
            <input
              id="dnsInput"
              className="input"
              type="text"
              value={value}
              placeholder="192.168.1.1"
              onChange={(e) => handleDNSChange(e, index)}
            />
            <div id="onRemoveDNSButton" className="remove-ntp-btn" onClick={() => onRemoveDNS(index)}>
              <img src={remove} alt="remove" className="remove" />
            </div>
          </div>
        ))}
      </div>

      <button id="onAddDNSButton" className="button add-more-btn" onClick={onAddDNS} type="button">
        <img src={addmore} alt="addmore" className="addmore" />
        &nbsp;Add more
      </button>
    </div>
  );
}

function HiddenNetwork({ config, handleConfigChange }: Props) {
  return (
    <div className="form-group">
      <div className="input-toggle">
        <label id="hiddenTitle">Hidden Network</label>
        <ToggleSwitch
          id="hidden"
          name="hidden"
          checked={config.hidden}
          onChange={() => {
            handleConfigChange('hidden', !config.hidden);
          }}
          optionLabels={['yes', 'no']}
          small={true}
          disabled={false}
        />
      </div>
    </div>
  );
}

function HiddenOption(props: Props) {
  const { config } = props;

  if (config.mode === 'ethernet') return <></>;

  return (
    <>
      <HiddenNetwork {...props} />
    </>
  );
}

export default Advanced;
