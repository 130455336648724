import { useEffect } from 'react';
import wordmark from '../icons/wordmark.svg';
import './ErrorReport.css';

function Support() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    script.async = true;
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: 'na1',
          portalId: '8640103',
          formId: '2093f7df-112d-4bac-8022-56a1f678eddd',
          target: '#hubspotForm',
          cssRequired: `.hs-form-field label { color: #FFFFFF !important; } \
          .hs-button { width: 100% !important; font-size: 1.2rem !important; padding: 1.2rem !important; } \
          .hs-input { width: 100% !important; max-width: 100% !important; } \
          .hs-fieldtype-textarea { min-height: 200px !important; } \
          `,
        });
      }
    });
  }, []);

  return (
    <div className="container error-report">
      <div>
        <img src={wordmark} alt="wordmark" className="wordmark" />
        <h1>Report to Customer Support</h1>
        <div id="hubspotForm"></div>
      </div>
    </div>
  );
}

export default Support;
