import { TZ } from '../libs/timezone';
import { Config } from './types';

interface TimezoneProps {
  handleConfigChange: (key: keyof Config, value: any) => void;
  config: string;
}

export interface TimeZoneSelectProps {
  onChange(value: string): void;
  value: string;
}

export const defaultDeviceTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function TimeZoneSelect({ onChange, value }: TimeZoneSelectProps) {
  return (
    <select id="timeZoneSelect" value={value} onChange={(e) => onChange(e.target.value)}>
      {TZ.map((v) => (
        <option key={v}>{v}</option>
      ))}
    </select>
  );
}

function Timezone(props: TimezoneProps) {
  const handleTimezoneChange = (timezone: string) => {
    props.handleConfigChange('timezone', timezone);
  };

  return (
    <div className="form-group">
      <label id="timeZoneTitle">
        <span>Timezone</span>
      </label>
      <div className="input-container">
        <div className="field">
          <div className="control">
            <div className="select  is-fullwidth">
              <TimeZoneSelect onChange={(value) => handleTimezoneChange(value)} value={props.config} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timezone;
